import { Module } from 'vuex'
import { IRootState } from '@/store/types'
import { IHomeState } from './types'
import { ElMessage } from 'element-plus'
import {
  getPageListData,
  deletePageData,
  createPageData,
  editPageData,
  processPageData,
  sendOutGoods
} from '@/servise/main/home/home'
// import { generateReservationCode } from '@/servise/main/reservation/reservation'
import LocalCache from '@/utils/cache'

const homeModule: Module<IHomeState, IRootState> = {
  namespaced: true,
  state() {
    return {
      roleList: [],
      roleCount: 0,
      imageGroupsList: [],
      imageGroupsCount: 0,
      userList: [],
      userCount: 0,
      videoList: [],
      videoCount: 0,
      productList: [],
      productCount: 0,
      processList: [],
      processCount: 0,
      exchangeRecordsList: [],
      exchangeRecordsCount: 0,
      lessonList: [],
      lessonCount: 0,
      lessonOrdersList: [],
      lessonOrdersCount: 0,
      engineerList: [],
      engineerCount: 0,
      appointmentsList: [],
      appointmentsCount: 0,
      reservationCodeList: [],
      reservationCodeCount: 0,
      recentlyList: [],
      recentlyCount: 0,
      customerList: [],
      customerCount: 0
    }
  },
  mutations: {
    changeRoleList(state, list: any[]) {
      state.roleList = list
    },
    changeRoleCount(state, count: number) {
      state.roleCount = count
    },
    changeImageGroupsList(state, list: any[]) {
      state.imageGroupsList = list
    },
    changeImageGroupsCount(state, count: number) {
      state.imageGroupsCount = count
    },
    changeUserList(state, list: any[]) {
      state.userList = list
    },
    changeUserCount(state, count: number) {
      state.userCount = count
    },
    changeVideoList(state, list: any[]) {
      state.videoList = list
    },
    changeVideoCount(state, count: number) {
      state.videoCount = count
    },
    changeProductList(state, list: any[]) {
      state.productList = list
    },
    changeProductCount(state, count: number) {
      state.productCount = count
    },
    changeProcessList(state, list: any[]) {
      state.processList = list
    },
    changeProcessCount(state, count: number) {
      state.processCount = count
    },
    changeExchangeRecordsList(state, list: any[]) {
      state.exchangeRecordsList = list
    },
    changeExchangeRecordsCount(state, count: number) {
      state.exchangeRecordsCount = count
    },
    changeLessonList(state, list: any[]) {
      state.lessonList = list
    },
    changeLessonCount(state, count: number) {
      state.lessonCount = count
    },
    changeLessonOrdersList(state, list: any[]) {
      state.lessonOrdersList = list
    },
    changeLessonOrdersCount(state, count: number) {
      state.lessonOrdersCount = count
    },
    changeEngineerList(state, list: any[]) {
      state.engineerList = list
    },
    changeEngineerCount(state, count: number) {
      state.engineerCount = count
    },
    changeAppointmentsList(state, list: any[]) {
      state.appointmentsList = list
    },
    changeAppointmentsCount(state, count: number) {
      state.appointmentsCount = count
    },
    changeReservationCodeList(state, list: any[]) {
      state.reservationCodeList = list
    },
    changeReservationCodeCount(state, count: number) {
      state.reservationCodeCount = count
    },
    changeRecentlyList(state, list: any[]) {
      state.recentlyList = list
    },
    changeRecentlyCount(state, count: number) {
      state.recentlyCount = count
    },
    changeCustomerList(state, list: any[]) {
      state.customerList = list
    },
    changeCustomerCount(state, count: number) {
      state.customerCount = count
    }
  },
  getters: {
    pageListData(state) {
      return (pageName: string) => {
        return (state as any)[pageName + 'List']
      }
    },
    pageListCount(state) {
      return (pageName: string) => {
        return (state as any)[pageName + 'Count']
      }
    }
  },
  actions: {
    // 获取数据
    async getPageListAction(
      { commit },
      payload: { pageName: string; queryInfo?: any }
    ) {
      // 映射页面名称到API路径的映射表
      const pageUrlMap = {
        role: '/setting/findRoleList',
        imageGroups: '/imgGroup/getImgGroup',
        process: '/imgGroup/getImgGroup', // 如果与imageGroups相同，考虑合并逻辑或确认是否正确
        video: '/live/findVideoList',
        product: '/live/findProductList',
        exchangeRecords: '/order/findPointsOrder',
        lesson: '/lesson/findLessonList',
        lessonOrders: '/lessonOrder/findLessonOrder',
        engineer: '/engineer/findEngineerList',
        appointments: 'reservation/findReservationList',
        recently: 'reservation/recentlyReservationList',
        reservationCode: 'reservation/findReservationCode',
        customer: 'adminUser/findCustomerList'
      }

      const { pageName, queryInfo } = payload
      const pageUrl = pageUrlMap[pageName] || ''

      if (pageName === 'user') {
        // 对于用户列表，直接从缓存获取数据
        const list = LocalCache.getCache('userMenus')
        commit('changeUserList', list)
        commit('changeUserCount', 1) // 用户计数固定为1，考虑是否有更合适的值
        return
      }

      if (!pageUrl) {
        console.error(`No API URL found for pageName: ${pageName}`)
        ElMessage({
          message: `无法找到对应页面：${pageName}`,
          type: 'error'
        })
        return
      }

      try {
        // 发送网络请求并处理结果
        const pageResult = await getPageListData(pageUrl, queryInfo)
        console.log(pageResult)

        // 检查响应的状态码和数据是否存在
        if (pageResult.code === 200 && pageResult.data) {
          const { list = [], totalCount = 0 } = pageResult.data

          // 动态生成mutation名称
          const changePageName =
            pageName.charAt(0).toUpperCase() + pageName.slice(1)
          commit(`change${changePageName}List`, list)
          commit(`change${changePageName}Count`, totalCount)
        } else {
          console.warn(
            `Received unexpected response for ${pageName}:`,
            pageResult
          )
          ElMessage({
            message: '收到的数据不符合预期.',
            type: 'warning'
          })
        }
      } catch (error) {
        console.error(`Error fetching data for ${pageName}:`, error)
        ElMessage({
          message: '请求失败，请稍后再试.',
          type: 'error'
        })
      }
    },
    //删除数据
    async deletePageDataAction({ dispatch }, payload: any) {
      const { pageName, objectId } = payload
      const className = pageName.slice(0, 1).toUpperCase() + pageName.slice(1)
      const pageUrl = '/common/removeById'
      //1、调用删除网络请求
      await deletePageData(pageUrl, { className, objectId })

      //2、重新请求最新数据
      dispatch('getPageListAction', {
        pageName,
        queryInfo: {
          page: 0,
          size: 10,
          status: payload.status
        }
      })
    },
    //新建
    async createPageDataAction({ dispatch }, payload: any) {
      //1、创建数据请求
      const { pageName, params } = payload
      console.log(params)
      if (params.date) {
        params.date = {
          __type: 'Date',
          iso: new Date(params.date).toISOString()
        }
      }

      const className = pageName.slice(0, 1).toUpperCase() + pageName.slice(1)
      let pageUrl = ''
      switch (pageName) {
        case 'imageGroups':
          pageUrl = '/common/insert'
          break
        case 'engineer':
          pageUrl = '/common/insert'
          break
        case 'appointments':
          pageUrl = '/common/insert'
          break
        case 'video':
          pageUrl = '/common/insert'
          break
        case 'user':
          pageUrl = ''
          break
      }
      const results = await createPageData(pageUrl, { className, params })
      console.log(results)

      //2、请求最新数据
      dispatch('getPageListAction', {
        pageName,
        queryInfo: {
          page: 0,
          size: 10
          // status: payload.status
        }
      })
    },
    //编辑
    async editPageDataAction({ dispatch }, payload: any) {
      // 1、编辑数据请求
      const { pageName, editData, objectId } = payload
      console.log(editData, pageName)

      const className = pageName.slice(0, 1).toUpperCase() + pageName.slice(1)

      let pageUrl = ''
      switch (pageName) {
        case 'imageGroups':
          pageUrl = '/common/updateById'
          break
        case 'exchangeRecords':
          pageUrl = '/common/updateById'
          break
        case 'engineer':
          pageUrl = '/common/updateById'
          break
        case 'appointments':
          pageUrl = '/common/updateById'
          break
        case 'customer':
          pageUrl = '/common/updateById'
          break
        case 'video':
          pageUrl = '/common/updateById'
          break
        case 'user':
          pageUrl = ''
          break
        default:
          throw new Error(`Unsupported page name: ${pageName}`)
      }

      await editPageData(pageUrl, { objectId, className, params: editData })

      // 2、请求最新数据
      dispatch('getPageListAction', {
        pageName,
        queryInfo: {
          page: 0,
          size: 10,
          status: payload.status,
          className
        }
      })
    },
    //发货
    async deliverPageDataAction({ dispatch }, payload: any) {
      //1、编辑数据请求
      const { objectId, params, isShow } = payload

      await sendOutGoods('/order/sendOutGoods', { objectId, params })
      //2、请求最新数据
      dispatch('getPageListAction', {
        queryInfo: {
          page: 0,
          size: 10,
          isShow
        }
      })
    },
    //审核案例
    async processPageDataAction({ dispatch }, payload: any) {
      const { objectId } = payload
      const pageUrl = '/imgGroup/processPageData'
      const pageName = 'imageGroups'
      //1、调用审核网络请求
      const results = await processPageData(pageUrl, { objectId })
      console.log(results)

      //2、重新请求最新数据
      dispatch('getPageListAction', {
        pageName,
        queryInfo: {
          page: 0,
          size: 10,
          isShow: false
        }
      })
    }
  }
}

export default homeModule
