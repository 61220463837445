import { createStore, Store, useStore as useVuexStore } from 'vuex'
import { IRootState } from './types'
import { IStoreType } from './types'

import login from './login/login'
import home from './main/home/home'
import schedule from './main/schedules/schedules'
import reservation from './main/reservation/reservation'
import panel from './main/panel/panel'
import { getPageListData } from '@/servise/main/home/home'

const store = createStore<IRootState>({
  state() {
    return {
      name: 'coderwhy',
      age: 18,
      entireScene: [
        { title: '实景', value: 'gd7IbwHiyy' },
        { title: '绿幕', value: 'iwN5MCef8w' }
      ],
      entireCategory: [],
      entireCamera: [],
      entireLighting: [],
      entireVideo: [],
      entireProduct: [
        { title: '相机', value: 'camera' },
        { title: '灯光', value: 'lighting' }
      ],
      entireEngineer: [],
      entireShift: [],
      entireTimeSlot: [],
      entireEngineerUser: [],
      entireMenus: []
    }
  },
  mutations: {
    changeEntireCategory(state, list) {
      state.entireCategory = list
    },
    changeEntireCamera(state, list) {
      state.entireCamera = list
    },
    changeEntireLighting(state, list) {
      state.entireLighting = list
    },
    changeEntireVideo(state, list) {
      state.entireVideo = list
    },
    changeEntireEngineer(state, list) {
      state.entireEngineer = list
    },
    changeEntireShift(state, list) {
      state.entireShift = list
    },
    changeEntireTimeSlot(state, list) {
      state.entireTimeSlot = list
    },
    changeEntireEngineerUser(state, list) {
      state.entireEngineerUser = list
    },
    changeEntireMenus(state, list) {
      state.entireMenus = list
    }
  },
  getters: {},
  actions: {
    async getInitiaDataAction({ commit }) {
      //1、请求类别、相机灯光数据
      const categoryResult = await getPageListData(
        '/imgGroup/findOptionsList',
        {
          optionName: 'Category',
          type: 'screen'
        }
      )
      const cameraResult = await getPageListData('/imgGroup/findOptionsList', {
        optionName: 'Camera'
      })
      const engineerResult = await getPageListData(
        '/imgGroup/findOptionsList',
        {
          optionName: 'Engineer',
          isShow: true
        }
      )

      const lightingResult = await getPageListData(
        '/imgGroup/findLightList',
        {}
      )
      // 视频所属分类选项
      const videoResult = await getPageListData('/live/findVideoCategory', {})
      const shiftResult = await getPageListData('/imgGroup/findOptionsList', {
        optionName: 'Shifts'
      })
      const timeSlotResult = await getPageListData(
        '/imgGroup/findOptionsList',
        {
          optionName: 'TimeSlots'
        }
      )
      const engineerUserResult = await getPageListData(
        '/imgGroup/findEngineerUserId',
        {}
      )
      const menusResult = await getPageListData('/setting/findMenusList', {})
      console.log('menusResult', menusResult)

      commit('changeEntireCategory', categoryResult.data)
      commit('changeEntireCamera', cameraResult.data)
      commit('changeEntireLighting', lightingResult.data)
      commit('changeEntireVideo', videoResult.data)
      commit('changeEntireEngineer', engineerResult.data)
      commit('changeEntireShift', shiftResult.data)
      commit('changeEntireTimeSlot', timeSlotResult.data)
      commit('changeEntireEngineerUser', engineerUserResult.data)
      commit('changeEntireMenus', menusResult.data)
    }
  },
  modules: {
    login,
    home,
    schedule,
    reservation,
    panel
  }
})

export function setupStore() {
  store.dispatch('login/loadLocalLogin')
  // store.dispatch('getInitiaDataAction')
}

export function useStore(): Store<IStoreType> {
  return useVuexStore()
}

export default store
